'use client' // Required because of onClick events

import { Alert, AlertTitle, Box, Typography, Button, Grid, NoSsr, Fade, CircularProgress, Stack } from '@mui/material'
import Link from 'next/link'
import { signIn } from 'next-auth/react'
import { meaningfulErrorDescription } from './data'
import { useEffect } from 'react'

const AZURE_AD_PROVIDER = 'azure-ad'

const generateAzureADLoginCallback = (callbackUrl) => () => {
  signIn(AZURE_AD_PROVIDER, { callbackUrl })
}

export const Error: React.FC<{ error: string; callbackUrl: string }> = ({ error, callbackUrl }) => {
  const meaningfulErrorText = meaningfulErrorDescription[error] || meaningfulErrorDescription.Default

  return (
    <Alert variant='outlined' severity='error'>
      <AlertTitle>An error occurred during the sign-in process</AlertTitle>
      <Box component='main'>
        <Typography component='p' variant='body2' sx={{ mt: 3 }}>
          Try signing in again. If this error keeps occurring consider reaching out to the{' '}
          <Link
            target='_blank'
            href='https://teams.microsoft.com/l/channel/19%3Ab1e1fd4ae96c457f8247d08b4e788728%40thread.skype/tech-online-devtools?groupId=2d829526-a0bd-4ab8-8230-2ad9be336d9d&tenantId=72f988bf-86f1-41af-91ab-2d7cd011db47'>
            tech-online-devtools team
          </Link>{' '}
          on Microsoft Teams if this error keeps occuring.
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography component='p' variant='body2'>
            <b>Error</b>{' '}
            <code>
              [{error}]: {meaningfulErrorText}
            </code>
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Button variant='contained' onClick={generateAzureADLoginCallback(callbackUrl)}>
            Sign in
          </Button>
        </Box>
      </Box>
    </Alert>
  )
}

export const SignedOut: React.FC<{ callbackUrl: string }> = ({ callbackUrl }) => (
  <Alert variant='outlined' severity='info'>
    <AlertTitle>You are logged out</AlertTitle>
    <Box component='main'>
      <Typography component='p' variant='body2' sx={{ mt: 2 }}>
        This application requires you to be authenticated.
      </Typography>
      <Typography component='p' variant='body2' sx={{ mt: 1 }}>
        To log in back in, click the button below.
      </Typography>
    </Box>
    <Box sx={{ mt: 3 }}>
      <Button variant='contained' onClick={generateAzureADLoginCallback(callbackUrl)}>
        Sign in
      </Button>
    </Box>
  </Alert>
)

export const SigninRedirect: React.FC<{ callbackUrl: string }> = ({ callbackUrl }) => {
  useEffect(() => {
    signIn(AZURE_AD_PROVIDER)
  }, [])

  return (
    <Grid container spacing={0} direction='column' alignItems='center' justifyContent='center' style={{ minHeight: '100vh' }}>
      <Grid item xs={3}>
        <NoSsr>
          <Fade
            in={true}
            style={{
              transitionDelay: '200ms'
            }}>
            <Stack spacing={1} direction='column' alignItems='center'>
              <CircularProgress disableShrink />
              <Typography>Signing in</Typography>
              <Typography variant='body2' sx={{ mt: 2, mb: 1 }}>
                If you are not redirected, click the button below.
              </Typography>
              <Box sx={{ mt: 3 }}>
                <Button variant='contained' onClick={generateAzureADLoginCallback(callbackUrl)}>
                  Sign in
                </Button>
              </Box>
            </Stack>
          </Fade>
        </NoSsr>
      </Grid>
    </Grid>
  )
}
