export const errorSigninPageSignIn = new Error('SigninPage_SigninError')

// The error codes are listed here: https://next-auth.js.org/configuration/pages#sign-in-page
export const meaningfulErrorDescription = {
  OAuthSignin: 'Error in consutrcting an authorization URL',
  OAuthCallback: 'Error in handling the response from Azure AD',
  Callback: 'Error in the OAuth callback handler route',
  SessionRequired: 'The content of this page requires you to be signed in at all times',
  Default: 'Unknown error'
}
